import dayjs from 'dayjs';

const dateFormat = value => {
  return dayjs(value).format('YYYY-MM-DD HH:mm-ss');
};

const amountFormat = value => {
  return value.toLocaleString('en-US');
};

export {
  dateFormat,
  amountFormat,
};
